import React, { useEffect, useState } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams } from "react-router-dom";
//API
import { bookViewingSlot, cancelViewingSlot, confirmScreening, getScreening, getViewingSlots, postQuestion, rescheduleViewingSlot, updateUserDetails, validateQuestions } from "./api/prospect";
//Components
import MobileView from "./views/MobileView";
import ErrorView from "./views/ErrorView";
import LoadingView from "./views/LoadingView";
import SlotTypeDialog from './components/SlotTypeDialog';
import { bookedDateTemplate, bookedErrorTemplate, bookedTemplate, cancelledRescheduleTemplate, cancelMainTemplate, confirmDateTemplate, confirmedTemplate, confirmRescheduleTemplate, confirmSessionSubtitleTemplate, confirmSessionTemplate, contactTemplate, errorDateTemplate, failedTemplate, rescheduleSucessTemplate, reviewTemplate, screeningErrorTemplate, slotsTemplate, validTemplate } from "./strings/templates";
//Types
import { ScreeningQuestion, ScreeningSession, ScreeningAnswer } from "./types/ScreeningSession";
import { QuestionAnswer } from "./types/QuestionAnswer";
import { ViewingSlots } from "./types/ViewingSlots";
import { Box } from "@mui/material";
import { BookingDate } from "./types/BookingDate";
// Tools
import DateTimeUtils from "./utils/DateTimeUtils";

const theme = createTheme({
  palette: {
    primary: {
      main: '#474ed1',
      light: '#666bc9',
      dark: '#2c318e',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(255,255,255,0.87)',
      disabled: '#2c318e',
    },
  },
  typography: {
    // fontFamily: "'Poppins', sans-serif",
    fontFamily: "'Montserrat', sans-serif",
  },
});

const renderStages = ['questions', 'cancelledSession', 'confirmed', 'review', 'slots', 'userDetails', 'success', 'error', 'booked', 'welcome'];

function Screening() {
  const { id: screeningId = '' } = useParams();

  const [stage, setStage] = useState<string>('');
  const [slots, setSlots] = useState<[ViewingSlots] | any[]>([{}, {}]);
  const [viewerName, setViewerName] = useState<string>('');
  const [viewerEmail, setViewerEmail] = useState<string>('');
  const [viewerPhone, setViewerPhone] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [valueError, setValueError] = useState<boolean>(false);
  const [session, setSession] = useState<ScreeningSession | null>(null);
  const [questions, setQuestions] = useState<ScreeningQuestion[]>([]);
  const [postision, setpostision] = useState<number>(0);
  const [selectedQuestion, setSelectedQuestion] = useState<ScreeningQuestion | null>(null);
  const [answers, setAnswers] = useState<ScreeningAnswer[] | any[]>([]);
  const [valueAns, setValueAns] = useState<number>(0);
  const [radioAns, setRadioAns] = useState<boolean | null>(null);
  const [bookingDate, setBookingDate] = useState<BookingDate | null>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [slotTypeDialog, setSlotTypeDialog] = useState<boolean>(false);

  const reviewSession = async () => {
    // POST with empty body
    const params = {
      viewingSlotId: 'viewingSlotId'
    }
    const res: ScreeningSession = await validateQuestions(screeningId, params)
    const { status } = res;
    if (status === 'succeeded') {
      setStage('slots')
      setSelectedQuestion({
        question: slotsTemplate()
      })
    }
    else if (status === 'failed') {
      setStage('error')
      setSelectedQuestion({
        question: failedTemplate()
      })
    }
  }
  const bookSession = async () => {
    const { dateAndTime = '', id = '', isVirtual = false } = bookingDate || {};
    
    const params = {
      viewingSlotId: id,
      isVirtual,
    }

    try {
      setButtonLoading(true);
      let res: ScreeningSession
      /* @ts-ignore */
      const { status: sessionStatus } = session || {};

      if (sessionStatus === 'created' || sessionStatus === 'succeeded') {
        res = await bookViewingSlot(screeningId, params)
      }
      else {
        res = await rescheduleViewingSlot(screeningId, params)
      }

      const {
        status,
        propertyAddress,
        advert = { virtualMeetingDetails: '' },
        isVirtual = false,
      } = res;
      const { virtualMeetingDetails = '' } = advert;

      setButtonLoading(false);
      if (status === 'booked') {
        const slotDate = DateTimeUtils.Format.screeningSlot(DateTimeUtils.parse(dateAndTime));
        if (sessionStatus === 'created' || sessionStatus === 'succeeded') {
          setSelectedQuestion({
            question: bookedTemplate(),
            subtitle: bookedDateTemplate(slotDate, propertyAddress),
            markdown: isVirtual ? virtualMeetingDetails : ''
          })
        }
        else {
          setSelectedQuestion({
            question: rescheduleSucessTemplate(),
            subtitle: bookedDateTemplate(slotDate, propertyAddress),
            markdown: isVirtual ? virtualMeetingDetails : ''
          })
        }
        setStage('success');
      }
      else if (status === 'failed') {
        setStage('error')
        setSelectedQuestion({
          question: bookedErrorTemplate()
        })
      }
    }
    catch (err: any) {
      console.error(err)
      setButtonLoading(false);
      setStage('error')
      setSelectedQuestion({
        question: bookedErrorTemplate()
      })
      setError(true)
    }
  }
  const updateUser = async () => {
    const params = {
      email: viewerEmail,
      name: viewerName
    }
    try {
      await updateUserDetails(screeningId, params)
    }
    catch (err: any) {
      console.error(err)
      setStage('error')
      setSelectedQuestion({
        question: bookedErrorTemplate()
      })
      setError(true)
    }
  }
  const cancelSession = async () => {
    try {
      setButtonLoading(true);
      const res: ScreeningSession = await cancelViewingSlot(screeningId)
      const { status } = res;
      if (status === 'cancelledSession') {
        setSelectedQuestion({
          question: cancelMainTemplate(),
          subtitle: ''
        })
        setStage('cancelledSession');
      }
      else {
        setSelectedQuestion({
          question: cancelMainTemplate(),
          subtitle: ''
        })
        setStage('cancelledSession');
      }
      setButtonLoading(false);
    }
    catch (err: any) {
      setButtonLoading(false);
      console.error(err)
      setStage('error')
      setSelectedQuestion({
        question: bookedErrorTemplate()
      })
      setError(true)
    }
  }
  const confirmSession = async () => {
    try {
      const res: ScreeningSession = await confirmScreening(screeningId)
      const { status } = res;
      if (status === 'confirmed') {
        setSelectedQuestion({
          question: confirmSessionTemplate(),
          subtitle: confirmSessionSubtitleTemplate()
        })
        setStage('success');
      }
      else {
        setStage('error')
        setSelectedQuestion({
          question: bookedErrorTemplate()
        })
        setError(true)
      }
    }
    catch (err: any) {
      console.error(err)
      setStage('error')
      setSelectedQuestion({
        question: bookedErrorTemplate()
      })
      setError(true)
    }
  }
  useEffect(() => {
    const sendIntr = async () => {
      try {
        const screening: ScreeningSession = await getScreening(screeningId);
        const {
          screeningQuestions = [],
          screeningAnswers = [],
          viewer = {},
          advert = { virtualMeetingDetails: '' },
          viewingSlot,
          status = '',
          propertyAddress,
          isVirtual = false,
        } = screening;

        const { virtualMeetingDetails = '' } = advert;

        /* @ts-ignore */
        const { email = '', name = '', phone = '' } = viewer || {};
        const { dateAndTime = '' } = viewingSlot || {};
        setViewerPhone(phone || '');
        setViewerEmail(email || '');
        setViewerName(name || '');
        setSession(screening);
        setQuestions(screeningQuestions);
        setSelectedQuestion(screeningQuestions[0])
        setpostision(0)
        setAnswers(screeningAnswers);

        switch (status) {
          case 'created':
            setStage('welcome')
            setSelectedQuestion({
              question: ''
            })
            break;
          case 'succeeded':
            setStage('slots')
            setSelectedQuestion({
              question: slotsTemplate()
            })
            break;
          case 'failed':
            setStage('error')
            setSelectedQuestion({
              question: failedTemplate()
            })
            break;
          case 'booked':
            const bookedDate = DateTimeUtils.Format.bookedDate(DateTimeUtils.parse(dateAndTime));
            setStage('booked')
            setSelectedQuestion({
              question: confirmRescheduleTemplate(),
              subtitle: confirmDateTemplate(bookedDate, propertyAddress),
            })
            break;
          case 'cancelled':
            setStage('booked')
            setSelectedQuestion({
              question: cancelledRescheduleTemplate(),
              subtitle: ''
            })
            break;
          case 'confirmed':
            const confirmedDate = DateTimeUtils.Format.confirmedDate(DateTimeUtils.parse(dateAndTime));
            setStage('confirmed')
            setSelectedQuestion({
              question: confirmedTemplate((screening as any).propertyAddress, confirmedDate),
              markdown: isVirtual ? virtualMeetingDetails : ''
            })
            break;
          default:
            console.info(status)
        }

        const viewingSlots: ViewingSlots[] = await getViewingSlots(screeningId);
        setSlots(viewingSlots);
        setLoading(false)
      }
      catch (err: any) {
        console.error(err)
        setSelectedQuestion({
          question: screeningErrorTemplate(screeningId)
        })
        setError(true)
        setLoading(false)
      }
    }
    sendIntr();
  }, [])

  const handleBackClick = () => {
    setValueError(false);
    if (stage === 'questions' || stage === 'review') {
      if (postision > 0) {
        const newPos = postision - 1;
        const question = questions[newPos];
        const { type = '', id = '' } = question;
        const answer = answers.find((f) => f.screeningQuestionId === id) || {};
        const { valueAnswer } = answer;
        setSelectedQuestion(question);
        setpostision(newPos);
        if (type === 'yesno') {
          setRadioAns(valueAnswer === 1);
        }
        else {
          setValueAns(valueAnswer);
        }
        setStage('questions')
      }
    }
    if (stage === 'slots') {
      setStage('userDetails');
      return;
    }
    if (stage === 'userDetails') {
      setStage('review');
      return;
    }
  };

  const handleNextClick = (): undefined => {
    console.info('###NEXT_CLICK###')

    if (stage === 'review') {
      setSelectedQuestion({
        question: contactTemplate()
      })
      setStage('userDetails');
      return;
    }
    if (stage === 'questions') {
      const { type = '', id = '' } = selectedQuestion || {};

      if (type === "yesno" || valueAns) {
        const r: ScreeningAnswer = {
          screeningQuestionId: id,
          yesNoAnswer: type === 'yesno',
          valueAnswer: type === "yesno" ? (radioAns ? 1 : 0) : valueAns
        }

        const resp: QuestionAnswer = {
          // advertId: session?.advert.id || '',
          screeningQuestionId: selectedQuestion?.id || '',
          value: type === "yesno" ? radioAns : valueAns
        }

        postQuestion(screeningId, resp);
        const newPos = postision + 1;
        setSelectedQuestion(questions[newPos]);
        setpostision(newPos);

        let temp = [...answers];
        const { screeningQuestionId = 0, valueAnswer } = answers.find((f) => f.screeningQuestionId === id) || {};
        if (screeningQuestionId) {
          temp = temp.map((e) => {
            if (e.screeningQuestionId === id) {
              return ({ ...e, ...r });
            }
            else {
              return e;
            }
          })
          if (type === 'yesno') {
            setRadioAns(valueAnswer === null ? valueAnswer : valueAnswer === 1);
          }
          else {
            setValueAns(valueAnswer || 0);
            setRadioAns(false);
          }
        }
        else {
          temp.push(r);
          setValueAns(0);
          setRadioAns(false);
        }
        setAnswers(temp)
        if (questions.length === newPos) {
          setStage('review')
          setSelectedQuestion({
            question: reviewTemplate()
          })
        }
        else {
          const t = questions[newPos];
          const { valueAnswer = 0 } = answers.find((f) => f.screeningQuestionId === t.id) || {};
          const { type } = questions.find(d => d.id === t.id) || {};
          if (screeningQuestionId) {
            if (type === "yesno") {
              setRadioAns(valueAnswer === null ? valueAnswer : valueAnswer === 1);
            }
            else {
              setValueAns(valueAnswer || 0);
              setRadioAns(false);
            }
          }
          else {
            setValueAns(0);
            setRadioAns(false);
          }
        }

      } else {
        setErrorMessage(validTemplate())
        setValueError(true)
        return
      }
    }
    if (stage === 'slots') {
      if (bookingDate) {
        bookSession()
        return;
      }
      else {
        setErrorMessage(errorDateTemplate())
        setValueError(true)
        return;
      }
    }
    if (stage === 'userDetails') {
      updateUser()
      reviewSession();
      return;
    }
  };

  const handleSlotTypeChange = (isVirtual: boolean) => {
    const { id = '', dateAndTime = ''} = bookingDate || {};
    const _bookingDate = {
      id,
      dateAndTime,
      isVirtual,
    }
    setBookingDate(_bookingDate)
    setSlotTypeDialog(false);
  }

  const props = {
    selectedQuestion,
    questions,
    answers,
    slots,
    stage,
    setStage,
    setSelectedQuestion,
    handleNextClick,
    handleBackClick,
    postision,
    radioAns,
    setRadioAns,
    valueAns,
    setValueAns,
    bookingDate,
    setBookingDate,
    viewerEmail,
    setViewerEmail,
    viewerPhone,
    setViewerPhone,
    valueError,
    setValueError,
    errorMessage,
    setErrorMessage,
    cancelSession,
    session,
    viewerName,
    setViewerName,
    confirmSession,
    buttonLoading,
    slotTypeDialog,
    setSlotTypeDialog,
    handleSlotTypeChange,
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {
        slotTypeDialog && <SlotTypeDialog {...props} />
      }
      {
        error && selectedQuestion && <ErrorView {...props} />
      }
      {
        loading && <LoadingView />
      }
      {!error && !loading && renderStages.includes(stage) && selectedQuestion &&
        <Box sx={{ width: '100%', height: '100%', background: 'radial-gradient(192.51% 81.38% at 107.33% -8.1%, rgba(71, 78, 209, 0.59) 0%, rgba(71, 78, 209, 0) 100%), #0F1585', position: 'relative' }}>
          <Box sx={{ maxWidth: '500px', mx: 'auto', height: '100%' }}>
            <MobileView {...props} />
          </Box>
        </Box>
      }
    </ThemeProvider>
  );
}

export default Screening;
