import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { MainProps } from '../types/MainProps';
import { yesTemplate, noTemplate } from '../strings/templates';

const RadioGroupColor = styled(RadioGroup)(({ theme }) => ({
    color: 'white'
}));

const RadioColor = styled(Radio)(({ theme }) => ({
    color: '#73a9ff !important',
    "& .MuiSvgIcon-root": {
        height: 30,
        width: 30,
        }
}));

function RadioOptions(props: MainProps) {  
    const { selectedQuestion, radioAns, setRadioAns } = props;
    const { id } = selectedQuestion;

    return (
        <>
            <FormControl component="fieldset" sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <RadioGroupColor
                    aria-label="RadioOption"
                    name="radio-buttons-group"
                    value={radioAns === null ? '' : radioAns ? 'Yes' : 'No' }
                    onChange={(e) => setRadioAns(e.target.value === 'Yes')}
                    id={id}
                    key={id}
                >
                    <FormControlLabel
                        value="Yes"
                        control={<RadioColor />}
                        label={yesTemplate()}
                    />
                    <FormControlLabel
                        value="No"
                        control={<RadioColor />}
                        label={noTemplate()}
                    />
                </RadioGroupColor>
            </FormControl>
        </>
    );
}

export default RadioOptions;
