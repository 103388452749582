import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { indigo } from '@mui/material/colors';
import { groupBy, map } from 'lodash';
// Types
import { MainProps } from '../types/MainProps';
import { ViewingSlots } from "../types/ViewingSlots";
// Tools
import DateTimeUtils from "../utils/DateTimeUtils";

const AccordionColored = styled(Accordion)(({ theme }) => ({
    borderColor: '#485fc7',
    backgroundColor: '#eff1fa',
    borderRadius: 4,
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 4,
    marginBottom: 10
}));

function SlotsList(props: MainProps) {
    const { slots = [], setBookingDate, setValueError, setSlotTypeDialog } = props;
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [value, setValue] = React.useState('');
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueError(false);
        const id = event.target.value;
        const { isVirtual = false, isOnsite = false, dateAndTime } = slots.find((s) => s.id === id) || {};
        if(isVirtual && isOnsite) {
            setSlotTypeDialog(true);
        }
        setBookingDate({
            id,
            dateAndTime,
            isVirtual
        })
        setValue((event.target as HTMLInputElement).value);
    };

    const groupedSlots = groupBy(slots, (slot: ViewingSlots) => {
        const viewingSlotDate = DateTimeUtils.parse(slot.dateAndTime);
        return DateTimeUtils.Format.defaultDate(viewingSlotDate);
    });

    return (
        <div style={{
            minWidth: 230
        }}>
            {
                map(groupedSlots, (slots, groupId) => {
                    const date = DateTimeUtils.parse(groupId);
                    const viewingSlotGroupDate = DateTimeUtils.Format.viewingSlotGroupDate(date);
                    const id = groupId;

                    return (
                        <AccordionColored expanded={expanded === id} onChange={handleChange(id)} key={id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${id}-content`}
                                id={`${id}-header`}
                            >
                                <Typography><b>{viewingSlotGroupDate}</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="gender"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleRadioChange}
                                    >
                                        {
                                            slots.map((slot) => {
                                                
                                                const label = DateTimeUtils.Format.slotTime(DateTimeUtils.parse(slot.dateAndTime));

                                                return (
                                                    <Grid container key={`slot_${id}`}>
                                                        <Grid item>
                                                            <FormControlLabel value={slot.id} control={<Radio sx={{
                                                                color: indigo[400],
                                                                '&.Mui-checked': {
                                                                    color: indigo[700],
                                                                },
                                                            }} />} label={label} />
                                                        </Grid>
                                                        <Grid item>
                                                            <Stack direction="row" spacing={1}>
                                                                {
                                                                    slot?.isVirtual && <Chip label="Virtual" color="primary" sx={{ fontSize: 'x-small !important' }} />
                                                                }
                                                                {
                                                                    slot?.isOnsite && <Chip label="OnSite" color="secondary" sx={{ fontSize: 'x-small !important' }} />
                                                                }
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </AccordionColored>
                    )
                })
            }
        </div>
    );
}

export default SlotsList;
