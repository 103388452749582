import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { MainProps } from '../types/MainProps';

const TextFieldColor = styled(TextField)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white !important',
        }

}));

function ValueOption(props: MainProps) {  
    const { selectedQuestion, valueAns, setValueAns, setValueError } = props;
    const { id } = selectedQuestion;
    return (
        <>
            <FormControl sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} component="fieldset">
                <TextFieldColor
                    id={id}
                    type="number"
                    variant="outlined"
                    value={valueAns}
                    onChange={(e) => {
                        setValueError(false);
                        setValueAns(parseInt(e.target.value, 10))
                    }}
                />
            </FormControl>
        </>
    );
}

export default ValueOption;
