import { debounce } from "lodash";

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}

const setAppHeight = () => {
  window.addEventListener('resize', debounce(appHeight, 200));

  appHeight();
}

export default setAppHeight;
