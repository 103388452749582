import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { MainProps } from '../types/MainProps';
import { emailTemplate, nameTemplate } from '../strings/templates';
import config from "../config";

const TextFieldColor = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'white !important',
    }
}));
const TypographyColor = styled(Typography)(({ theme }) => ({
    color: 'white !important',
    marginTop: 10,
    fontSize: 14,
}));
const LinkColor = styled(Link)(({ theme }) => ({
    color: 'white !important',
    //marginTop: 10,
    //fontSize: 14,
}));
function UserDetails(props: MainProps) {
    const { viewerEmail, setViewerEmail, viewerName, setViewerName } = props;
    return (
        <>
            <FormControl sx={{ width: '100%' }} component="fieldset">
            <TypographyColor variant="caption" display="block" gutterBottom>
                    {nameTemplate()}
                </TypographyColor>
                <TextFieldColor
                    type="text"
                    id="userdetails-name"
                    // label={nameTemplate()}
                    variant="outlined"
                    value={viewerName}
                    fullWidth
                    onChange={(e) => {
                        setViewerName(e.target.value)
                    }}
                />
                <TypographyColor sx={{ mt: 2 }} variant="caption" display="block" gutterBottom>
                    {emailTemplate()}
                </TypographyColor>
                <TextFieldColor
                    type="text"
                    id="userdetails-email"
                    variant="outlined"
                    value={viewerEmail}
                    helperText={<LinkColor href={config.termsUrl} target="_blank" underline="always">* Terms & Conditions</LinkColor>}
                    fullWidth
                    onChange={(e) => {
                        setViewerEmail(e.target.value)
                    }}
                />
            </FormControl>
        </>
    );
}

export default UserDetails;