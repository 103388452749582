import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import RadioOptions from './RadioOptions';
import ValueOption from './ValueOption';
import ReviewList from './ReviewList';
import SlotsList from './SlotsList';
import SuccessResponse from './SuccessResponse';
import ErrorResponse from './ErrorResponse';
import UserDetails from './UserDetails';
import { MainProps } from '../types/MainProps';
import ErrorMessage from './ErrorMessage';
import ScheduleResponse from './ScheduleResponse';
import { backTemplate, nextTemplate } from '../strings/templates';
import WelcomeResponse from './WelcomeResponse';
import ConfirmedResponse from './ConfirmedResponse';
import CancelledSessionResponse from './CancelledSession';
import { Box } from "@mui/material";
import ActionButton from "./Buttons/ActionButton";

const renderStages = ['success', 'cancelledSession', 'confirmed', 'error', 'booked', 'welcome'];
function MobilePannelBottom(props: MainProps) {
    const { selectedQuestion, handleNextClick, handleBackClick, postision, stage, valueError, buttonLoading, radioAns } = props;
    const { type } = selectedQuestion;

    return (
        <Grid item sx={{ width: '100%', px: 2, alignItems: 'center', display: 'flex', flexDirection: 'column' }} xs={8}>
            {
              valueError &&
              <Box marginBottom={3}>
                <ErrorMessage {...props} />
              </Box>
            }

            <Box style={{flexGrow: 1}}>
              {
                  stage === 'questions' && type === "yesno" &&  <RadioOptions {...props} />
              }
              {
                  stage === 'questions' && type === "value" &&  <ValueOption {...props} />
              }
              {
                  stage === "review" && <ReviewList {...props} />
              }
              {
                  stage === "slots" && <SlotsList {...props} />
              }
              {
                  stage === "userDetails" && <UserDetails {...props} />
              }
              {
                  stage === "success" && <SuccessResponse {...props} />
              }
              {
                  stage === "error" && <ErrorResponse {...props} />
              }
              {
                  stage === "booked" && <ScheduleResponse {...props} />
              }
              {
                  stage === "welcome" && <WelcomeResponse {...props} />
              }
              {
                  stage === "confirmed" && <ConfirmedResponse {...props} />
              }
              {
                  stage === "cancelledSession" && <CancelledSessionResponse {...props} />
              }
            </Box>

            <Box>
              {
                !renderStages.includes(stage) &&
                <Stack direction="row" spacing={2} style={{
                  paddingBottom: 20
                }}>
                  <ActionButton
                    variant="contained"
                    disabled={postision === 0 || stage === 'slots' }
                    size="large"
                    onClick={() => handleBackClick()}
                  >
                    {backTemplate()}
                  </ActionButton>

                  <ActionButton
                    loading={buttonLoading}
                    disabled={buttonLoading || (stage === 'questions' && selectedQuestion.type === 'yesno' && radioAns === null) }
                    variant="contained"
                    size="large"
                    onClick={() => handleNextClick()}
                  >
                    {nextTemplate()}
                  </ActionButton>
                </Stack>
              }
            </Box>
        </Grid>
    );
}

export default MobilePannelBottom;
