import axios from 'axios';
import config from "../../config";

const DEFAULT_TIMEOUT = 15 * 60 * 1000;

const functionsClient = axios.create({
  baseURL: config.apiUrl,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default functionsClient;
