import { CalendarEvent, google } from "calendar-link";
import { googleLinkCalendarTitle, googleLinkCalendarDescription } from '../strings/templates';
// Tools
import DateTimeUtils from "../utils/DateTimeUtils";

const googleCalendarLink = (propertyAddress: string, dateAndTime: string) => {
  const _dateTime = DateTimeUtils.parse(dateAndTime);
  const timeFrom = DateTimeUtils.Format.calendarTime(_dateTime);
  const timeTo = DateTimeUtils.Format.calendarTime(DateTimeUtils.Manipulate.add(_dateTime, 30, 'minutes'));
  const _event: CalendarEvent = {
    title: googleLinkCalendarTitle(propertyAddress),
    location: propertyAddress,
    description: googleLinkCalendarDescription(propertyAddress, timeFrom, timeTo),
    start: _dateTime.valueOf(),
    url: window.location.href,
    duration: [30, "minutes"],
  };
  const url = google(_event);
  window.open(url, "_blank")
}

export default googleCalendarLink;
