import React from 'react';
import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { green, red, orange } from '@mui/material/colors';
import { cancelTemplate, confirmTemplate, rescheduleTemplate, slotsTemplate, confirmCancelBookingTitle, confirmCancelBooking } from '../strings/templates';
import ActionButton from "./Buttons/ActionButton";
import {
    Grid,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import ReactMarkdown from 'react-markdown';
// Types
import { MainProps } from '../types/MainProps';
import congratulations from '../images/congratulations.webp';

const ConfirmButton = styled(ActionButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(green[400]),
    backgroundColor: green[400],
    '&:hover': {
        backgroundColor: green[600],
    },
}));

const CancelButton = styled(ActionButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(red[400]),
    backgroundColor: red[400],
    '&:hover': {
        backgroundColor: red[600],
    },
}));

const ScheduleButton = styled(ActionButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    '&:hover': {
        backgroundColor: orange[600],
    },
}));

const TypographyColor = styled(Typography)(({ theme }) => ({
    color: 'white',
    paddingLeft: 10,
    paddingRigth: 10,
}));

function ConfirmedResponse(props: MainProps) {
    const { setStage, setSelectedQuestion, selectedQuestion, cancelSession, session, confirmSession, buttonLoading } = props;
    const { question, markdown } = selectedQuestion;
    /* @ts-ignore */
    const { status = 'cancelled' } = session;
    const [open, setOpen] = React.useState(false);

    const handleConfirm = async () => {
        await cancelSession();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={8} sx={{ position: 'relative' }}>
                    <img src={congratulations} height="200" alt="Congratulations" />
                </Grid>
                <Grid item xs={2}>
                    <TypographyColor dangerouslySetInnerHTML={{ __html: question }} variant="h6" gutterBottom align="center" />
                </Grid>
                {
                    markdown && 
                    <>
                        <Grid item xs={12}><TypographyColor sx={{mt: 5 }} variant="h6" gutterBottom>
                            Virtual Meeting Details
                        </TypographyColor></Grid>
                        <Grid item xs={12} sx={{ width: '100%'}}><ReactMarkdown className="markdown">{markdown}</ReactMarkdown></Grid>
                    </>
                }
            <Grid item xs={12}>
                <Box>
                    <Stack spacing={2}>
                        {
                            session?.confirmable &&
                            <ConfirmButton
                                size="large"
                                variant="contained"
                                fullWidth
                                onClick={() => { confirmSession() }}
                            >
                                {confirmTemplate()}
                            </ConfirmButton>
                        }

                        <ScheduleButton
                            size="large"
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                setStage('slots')
                                setSelectedQuestion({
                                    question: slotsTemplate()
                                })
                            }}
                        >
                            {rescheduleTemplate()}
                        </ScheduleButton>

                        {
                            status !== 'cancelled' &&
                            <CancelButton
                                size="large"
                                variant="contained"
                                fullWidth
                                onClick={() => setOpen(true)}
                            >
                                {cancelTemplate()}
                            </CancelButton>
                        }
                    </Stack>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {confirmCancelBookingTitle()}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{ color: '#000' }} id="alert-dialog-description">
                                {confirmCancelBooking()}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton disabled={buttonLoading} onClick={handleClose} variant="contained">Cancel</LoadingButton>
                            <LoadingButton loading={buttonLoading} disabled={buttonLoading} variant="contained" color="error" onClick={() => handleConfirm()} autoFocus>
                                {confirmTemplate()}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Grid>

        </Grid>
        </>
    );
}

export default ConfirmedResponse;
