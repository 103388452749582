import { styled } from "@mui/material/styles";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: '1.3em'
}));

const ActionButton = (props: LoadingButtonProps) => {
  return (
    <StyledButton {...props} fullWidth />
  )
}

export default ActionButton;
