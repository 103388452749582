import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Screening from "./Screening";
import setAppHeight from "./utils/setAppHeight";

import './index.css';

setAppHeight();

render(
  <BrowserRouter>
    <Routes>
      <Route path="/:id" element={<Screening />} />
      <Route path="/screenings/:id" element={<Screening />} />

    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
