import React from 'react';
import { ButtonProps } from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { MainProps } from '../types/MainProps';
import { green } from '@mui/material/colors';
import ActionButton from "./Buttons/ActionButton";
import { blue } from '@mui/material/colors';
import TodayIcon from '@mui/icons-material/Today';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ReactMarkdown from 'react-markdown';

import { googleCalendar } from '../strings/templates';
import googleCalendarLink from '../utils/googleCalendarLink';

const TypographyColor = styled(Typography)(({ theme }) => ({
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
}));

const CalendarButton = styled(ActionButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[400]),
    fontSize: 'large',
    backgroundColor: blue[400],
    '&:hover': {
        backgroundColor: blue[600],
    },
}));

function SuccessResponse(props: MainProps) {
    const { selectedQuestion, session } = props;
    const { propertyAddress = '', viewingSlot } = session || {};
    const { dateAndTime = '' } = viewingSlot || {};
    const { question, subtitle, markdown } = selectedQuestion;
    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={8}>
                    <CheckCircleRoundedIcon sx={{ fontSize: 100 }} style={{
                        color: green[400]
                    }} />
                </Grid>
                <Grid item xs={2}>
                    <TypographyColor variant="h5" gutterBottom align="center">
                        {question}
                    </TypographyColor>
                </Grid>
                <Grid item xs={2}>
                    <TypographyColor variant="body1" gutterBottom align="center">
                        {subtitle}
                    </TypographyColor>
                </Grid>
                <Grid item >
                    <Box sx={{ mb: 5 }}>
                        {
                            markdown &&
                            <>
                                <Grid item xs={12}><TypographyColor sx={{ mt: 5 }} variant="h6" gutterBottom>
                                    Virtual Meeting Details
                                </TypographyColor></Grid>
                                <Grid item xs={12} sx={{ width: '100%' }}><ReactMarkdown className="markdown">{markdown}</ReactMarkdown></Grid>
                            </>
                        }
                        <CalendarButton
                            size="small"
                            variant="outlined"
                            fullWidth
                            onClick={() => googleCalendarLink(propertyAddress, dateAndTime)}
                            startIcon={<TodayIcon />}
                        >
                            {googleCalendar()}
                        </CalendarButton>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default SuccessResponse;
