import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const ListItemColored = styled(ListItem)(({ theme }) => ({
    borderColor: '#485fc7',
    backgroundColor: '#eff1fa',
    borderRadius: 4,
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 4,
    marginBottom: 10,
}));

interface ReviewItemProps {
    primary: string,
    secondary: string
}

function ReviewItem(props: ReviewItemProps) {
    const { primary, secondary } = props;
    return (
        <ListItemColored>
            <ListItemText primary={<Typography sx={{ fontSize: '18px' }}><strong>Q:</strong> {primary}</Typography>} secondary={<Typography sx={{ fontSize: '16px' }} variant="caption" display="block" gutterBottom>
            <strong>A:</strong> {secondary}
            </Typography>} />
        </ListItemColored>
    );
}

export default ReviewItem;
