import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { MainProps } from '../types/MainProps';

function SlotTypeDialog(props: MainProps) {
    const { slotTypeDialog = false, handleSlotTypeChange } = props;

    return (
        <Dialog open={slotTypeDialog} >
            <DialogTitle sx={{ backgroundColor: '#16182b', color: 'white' }} >Select Meeting Type</DialogTitle>
            <Box component="span" sx={{ p: 2, backgroundColor: '#16182b', color: 'white' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" size="large" onClick={() => handleSlotTypeChange(true)}>Virtual</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" size="large" onClick={() => handleSlotTypeChange(false)}>OnSite</Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
}

export default SlotTypeDialog;
