import React from 'react';
import Grid from '@mui/material/Grid';
import MobileTopPannel from '../components/MobileTopPannel';
import { MainProps } from '../types/MainProps';

function ErrorView(props: MainProps) {
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
        style={{
          height: '100vh',
          background: '#f14141'
        }}
      >
        <MobileTopPannel {...props} />
      </Grid>
    </>
  );
}

export default ErrorView;
