import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(isBetweenPlugin);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat)

type DateTimeFormats = 'long' | 'longHuman' | 'icsCalendar' | 'screeningSlot' | 'bookedDate' | 'confirmedDate' | 'viewingTime' | 'defaultDate' | 'viewingSlotGroupDate' | 'slotTime' | 'calendarTime';

const FORMATS: Record<DateTimeFormats, string> = {
  long: 'LLLL',
  longHuman: 'dddd, MMMM D, YYYY [at] h:mm A',
  icsCalendar: 'YYYYMMDDTHHmmss[Z]',
  screeningSlot: 'D MMMM YYYY H:mm A',
  bookedDate: 'D MMMM YYYY H:mm',
  confirmedDate: 'H:mm, D MMMM YYYY',
  viewingTime: 'dddd, MMMM D, YYYY [at] h:mm A',
  defaultDate:  'YYYY-MM-DD',
  viewingSlotGroupDate: 'dddd, Do MMMM YYYY',
  slotTime: 'h:mm A',
  calendarTime: 'hh:mm A'
}

const format = (datetime: dayjs.ConfigType, format: DateTimeFormats) => {
  return dayjs(datetime).format(FORMATS[format]);
}

const long = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'long');
}

const longHuman = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'longHuman');
}

const icsCalendar = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'icsCalendar');
}

const screeningSlot = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'screeningSlot');
}

const bookedDate = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'bookedDate');
}

const confirmedDate = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'confirmedDate');
}

const viewingTime = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'viewingTime');
}

const defaultDate = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'defaultDate');
}

const viewingSlotGroupDate = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'viewingSlotGroupDate');
}

const slotTime = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'slotTime');
}

const calendarTime = (datetime: dayjs.ConfigType) => {
  return format(datetime, 'calendarTime');
}

const now = () => {
  return dayjs();
}

const isBetween = (datetime: dayjs.ConfigType, datetime2: dayjs.ConfigType) => {
  return dayjs(datetime).isBetween(datetime, datetime2);
}

const parse = (datetime: dayjs.ConfigType): dayjs.Dayjs => {
  return dayjs(datetime);
}

const subtract = (value: number, unit: dayjs.OpUnitType): dayjs.Dayjs => {
  return dayjs().subtract(value, unit);
}

const add = (datetime: dayjs.Dayjs, value: number, unit: dayjs.OpUnitType): dayjs.Dayjs => {
  return datetime.add(value, unit);
}

const DateTimeUtils = {
  parse,
  now,
  isBetween,
  Format: {
    long,
    longHuman,
    icsCalendar,
    screeningSlot,
    bookedDate,
    confirmedDate,
    viewingTime,
    defaultDate,
    viewingSlotGroupDate,
    slotTime,
    calendarTime
  },
  Manipulate: {
    subtract,
    add
  },
}

export default DateTimeUtils;
