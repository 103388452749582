import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
// Types
import { MainProps } from '../types/MainProps';
import cancelledsession from '../images/cancelledsession.webp';

const TypographyColor = styled(Typography)(({ theme }) => ({
    color: 'white',
    paddingLeft: 10,
    paddingRigth: 10,
}));

function CancelledSessionResponse(props: MainProps) {
    const { selectedQuestion } = props;
    const { question } = selectedQuestion;
    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={8} sx={{ position: 'relative' }}>
                    <img src={cancelledsession} height="200" alt="Cancelled Session" />
                </Grid>
                <Grid item xs={2}>
                    <TypographyColor dangerouslySetInnerHTML={{ __html: question }} variant="h6" gutterBottom align="center" />
                </Grid>
            </Grid>
        </>
    );
}

export default CancelledSessionResponse;
