import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { MainProps } from '../types/MainProps';

const TypographyColor = styled(Typography)(({ theme }) => ({
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
}));

function ErrorResponse(props: MainProps) {
    const { selectedQuestion } = props;
    const { question } = selectedQuestion;
    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={2}>
                    <TypographyColor dangerouslySetInnerHTML={{ __html: question }} variant="h6" gutterBottom align="center" />
                </Grid>
            </Grid>
        </>
    );
}

export default ErrorResponse;
