const fetchEnvSetting = (setting: string, required: boolean = false): string | undefined => {
  const value = process.env[setting];

  if (required && !value) {
    throw new Error(`Missing config variable: ${setting}`);
  }

  return value;
}

const apiUrl = fetchEnvSetting('REACT_APP_API_URL', true)!;
const termsUrl = fetchEnvSetting('REACT_APP_TERMS_URL', true)!;

const config = Object.freeze({
  apiUrl,
  termsUrl,
});

export default config;
