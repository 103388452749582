import React from 'react';
import { MainProps } from '../types/MainProps';
import Box from '@mui/material/Box';
import { LinearProgress, Typography } from '@mui/material';

function QuestionProgress(props: MainProps) {
    const { questions = [], postision: position} = props;
    const progress = (position || 0 + 1)/questions.length * 100;
    return (
        <Box sx={{ width: '100%', mb: 2 }}>
        <Typography sx={{ color: '#fff', width: '100%', my: 1 }}>Question {(position || 0)+1} of {questions.length}</Typography>
        <LinearProgress sx={{ width: '100%', borderRadius: 10 }} variant="determinate" color="info" value={progress} />
      </Box>
    );
}

export default QuestionProgress;
