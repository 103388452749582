import compile from 'string-template/compile';

const phoneNumberTemplate = compile("Phone Number")
const nameTemplate = compile("Full Name")
const emailTemplate = compile("Email")
const nextTemplate = compile("Next")
const backTemplate = compile("Back")
const yesTemplate = compile("Yes")
const noTemplate = compile("No")
const rescheduleTemplate = compile("Rebook")
const confirmTemplate = compile("Confirm")
const cancelTemplate = compile("Cancel")
const cancelMainTemplate = compile(`
Hopefully we will see each other again soon!
<br>
Thanks
<br>
NEVO
`)
const startTemplate = compile("Start")
const slotsTemplate = compile("Please select a day & time that is most convenient for you")
const bookedTemplate = compile("You successfully created your booking")
const rescheduleSucessTemplate = compile("You successfully reschedule your booking")
const confirmSessionTemplate = compile("Session confirmed")
const confirmSessionSubtitleTemplate = compile("")
const bookedDateTemplate = compile("{1} at {0}")
const bookedErrorTemplate = compile("Unable to book session")
const failedTemplate = compile(`
😞
<br><br>
Unfortunately, your answers didn’t fit the criteria for this property.
<br><br>
We will pass your details on to the landlord, they may be in direct contact - if they wish to go ahead with a viewing.
<br><br>
Good luck in your search.
`)
const confirmRescheduleTemplate = compile("Please confirm or reschedule")
const cancelledRescheduleTemplate = compile("Reschedule a new booking")
const confirmDateTemplate = compile("you’ll be attending {1} at {0}")
const screeningErrorTemplate = compile("Screening Id {0} Not Found")
const reviewTemplate = compile("Please Review Your Answers")
const validTemplate = compile("Please enter a valid value")
const errorDateTemplate = compile("Please select a date and time")
const contactTemplate = compile("Contact Information")
const welcomeTemplate = compile(`
<iframe src="https://giphy.com/embed/xUPGGDNsLvqsBOhuU0" width="300" height="200" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
<p style="font-size: 16px;font-weight:400;">Let's run through a few questions before we book you in for reviewing.</p>
`)
const confirmedTemplate = compile(`
We are super excited that you’ll be attending {0} at {1}.
`);
const googleCalendar = compile("Add To Google Calendar")
const googleCalendarTitle = compile("Nevo Viewing Slot")
const confirmCancelBookingTitle = compile("Are you sure want to cancel the booking?")
const confirmCancelBooking = compile('Kindly could you confirm that you want cancel the Viewing by clicking ont "Confirm" button?')
const googleLinkCalendarTitle = compile("SUBJECT: VIEWING - {0}")
const googleLinkCalendarDescription = compile("ADDRESS: {0}\nTIME: {1} TO {2}")

export { 
    phoneNumberTemplate,
    nextTemplate,
    backTemplate,
    yesTemplate,
    noTemplate,
    rescheduleTemplate,
    rescheduleSucessTemplate,
    confirmTemplate,
    cancelTemplate,
    cancelMainTemplate,
    startTemplate,
    slotsTemplate,
    bookedTemplate,
    bookedDateTemplate,
    bookedErrorTemplate,
    confirmSessionTemplate,
    confirmSessionSubtitleTemplate,
    failedTemplate,
    confirmRescheduleTemplate,
    confirmDateTemplate,
    screeningErrorTemplate,
    reviewTemplate,
    validTemplate,
    errorDateTemplate,
    contactTemplate,
    welcomeTemplate,
    nameTemplate,
    emailTemplate,
    cancelledRescheduleTemplate,
    confirmedTemplate,
    googleCalendar,
    googleCalendarTitle,
    confirmCancelBookingTitle,
    confirmCancelBooking,
    googleLinkCalendarTitle,
    googleLinkCalendarDescription,
}