import React from 'react';
import List from '@mui/material/List';
import ReviewItem from './ReviewItem';
import { MainProps } from '../types/MainProps';

function ReviewList(props: MainProps) {
    const { questions = [], answers = []} = props;
    return (
        <List sx={{ width: '100%', p: 2, overflow: 'auto' }}>
            {
                questions.map((q) => {
                    const { id = '', question = '', type } = q;
                    const { screeningQuestionId = 0, valueAnswer = 0 } = answers.find((f) => f.screeningQuestionId === id) || {};
                    // @ts-ignore
                    return (<ReviewItem key={screeningQuestionId} primary={question} secondary={ type === "yesno" ? (valueAnswer === 1 ? 'Yes' : 'No') : valueAnswer} />)
                })
            }
        </List>
    );
}

export default ReviewList;
