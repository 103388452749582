import React, { ReactNode } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { green, red, orange, blue } from '@mui/material/colors';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    Grid,
} from "@mui/material";
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TodayIcon from '@mui/icons-material/Today';
import { LoadingButton } from '@mui/lab';
import ReactMarkdown from 'react-markdown';
// Components
import ActionButton from "./Buttons/ActionButton";
// String Templates
import { 
    cancelTemplate,
    confirmTemplate,
    googleCalendar,
    rescheduleTemplate,
    slotsTemplate,
    confirmCancelBooking,
    confirmCancelBookingTitle,
} from '../strings/templates';
// Types
import { MainProps } from '../types/MainProps';
// Tools
import googleCalendarLink from '../utils/googleCalendarLink';
import DateTimeUtils from "../utils/DateTimeUtils";

const TypographyColor = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    paddingLeft: 20,
    paddingRight: 20,
}));

const ConfirmButton = styled(ActionButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(green[400]),
    backgroundColor: green[400],
    '&:hover': {
        backgroundColor: green[600],
    },
}));

const CancelButton = styled(ActionButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(red[400]),
    backgroundColor: red[400],
    '&:hover': {
        backgroundColor: red[600],
    },
}));

const ScheduleButton = styled(ActionButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    '&:hover': {
        backgroundColor: orange[600],
    },
}));

const CalendarButton = styled(ActionButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[400]),
    fontSize: 'large',
    backgroundColor: blue[400],
    '&:hover': {
        backgroundColor: blue[600],
    },
}));

const InfoListItem = (props: {
    icon: ReactNode;
    text: string;
}) => {
    const { icon, text } = props;

    return (
        <ListItem>
            <ListItemIcon style={{ color: 'white' }}>
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={text}
                primaryTypographyProps={{
                    color: 'white',
                    variant: 'body1'
                }}
            />
        </ListItem>
    )
}


function ScheduleResponse(props: MainProps) {
    const { setStage, setSelectedQuestion, cancelSession, session, confirmSession, buttonLoading } = props;
    /* @ts-ignore */
    const { 
        status = 'cancelled',
        advert = { virtualMeetingDetails: '' },
        isVirtual = false,
        propertyAddress = '',
        viewingSlot
    } = session || {};
    const { dateAndTime = '' } = viewingSlot || {};
    const { virtualMeetingDetails = '' } = advert;
    const viewingTime = DateTimeUtils.Format.viewingTime((DateTimeUtils.parse(dateAndTime)));
    const [open, setOpen] = React.useState(false);

    const handleConfirm = async () => {
        await cancelSession();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: 16 }}>
            <Box>
                <TypographyColor variant="h5" gutterBottom>
                    Your viewing:
                </TypographyColor>

                <List dense>
                    <InfoListItem icon={<LocationOnIcon />} text={propertyAddress ?? ''} />
                    <InfoListItem icon={<ScheduleRoundedIcon />} text={viewingTime} />
                </List>
            </Box>
            <Box sx={{ mb: 5 }}>
                {
                    isVirtual && status !== 'cancelled' &&
                    <>
                        <Grid item xs={12}><TypographyColor sx={{ mt: 5 }} variant="h6" gutterBottom>
                            Virtual Meeting Details
                        </TypographyColor></Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}><ReactMarkdown className="markdown">{virtualMeetingDetails}</ReactMarkdown></Grid>
                    </>
                }
                <CalendarButton
                    size="small"
                    variant="outlined"
                    fullWidth
                    onClick={() => googleCalendarLink(propertyAddress, dateAndTime)}
                    startIcon={<TodayIcon />}
                >
                    {googleCalendar()}
                </CalendarButton>
            </Box>
            <Box>
                <Stack spacing={2}>
                    {
                        session?.confirmable &&
                        <ConfirmButton
                            size="large"
                            variant="contained"
                            fullWidth
                            onClick={() => { confirmSession() }}
                        >
                            {confirmTemplate()}
                        </ConfirmButton>
                    }

                    <ScheduleButton
                        size="large"
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            setStage('slots')
                            setSelectedQuestion({
                                question: slotsTemplate()
                            })
                        }}
                    >
                        {rescheduleTemplate()}
                    </ScheduleButton>

                    {
                        status !== 'cancelled' &&
                        <CancelButton
                            size="large"
                            variant="contained"
                            fullWidth
                            onClick={() => setOpen(true)}
                        >
                            {cancelTemplate()}
                        </CancelButton>
                    }
                </Stack>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {confirmCancelBookingTitle()}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ color: '#000' }} id="alert-dialog-description">
                        {confirmCancelBooking()}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton disabled={buttonLoading} onClick={handleClose} variant="contained">Cancel</LoadingButton>
                        <LoadingButton loading={buttonLoading} disabled={buttonLoading} variant="contained" color="error" onClick={() => handleConfirm()} autoFocus>
                            {confirmTemplate()}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    )
}

export default ScheduleResponse;
