import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { MainProps } from '../types/MainProps';

function ErrorMessage(props: MainProps) {
    const { errorMessage = '' } = props;
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="warning">{errorMessage}</Alert>
        </Stack>
    );
}

export default ErrorMessage;
