import { ScreeningAnswer } from '../types/BookViewingPost';
import { BookViewingSlot } from '../types/BookViewingSlot';
import { QuestionAnswer } from '../types/QuestionAnswer';
import { UserDetails } from '../types/UserDetails';
import { ValidateSession } from '../types/ValidateSession';
import client from './parse/functionsClient';

const GET_INFO_FUNCTION_PATH = 'functions/getInfo';
const CREATE_APPOINTMENT_PATH = 'functions/createAppointment';
const CREATE_REJECTION_PATH = 'functions/createRejection'
const ADD_ROOM_INTERACTION_PATH = 'functions/addRoomInteraction';
const CONFIRM_VIEWING_PATH = 'functions/confirmViewing';
const SCREENINGS_PATH = 'screenings';

const logError = (error : any) => console.log('error', error);

const getQuestions = (roomId : string) => {
  return client.post(GET_INFO_FUNCTION_PATH, {
    roomId,
    object: 'screeningQuestions'
  }).catch(logError);
};

const bookViewing = (name : string, phone : string, viewSlotId : string, questionResponses : ScreeningAnswer | any[]) => {
  return client.post(CREATE_APPOINTMENT_PATH, {
    name,
    phone,
    viewSlotId,
    screeningAnswers: questionResponses
  }).catch(logError);
}

const doRejection = (name : string, phone : string, questionResponses : ScreeningAnswer | any[], roomId : string | string[]) => {
  return client.post(CREATE_REJECTION_PATH, {
    name,
    phone,
    screeningAnswers: questionResponses,
    roomId: roomId
  }).catch(logError);
}

const addBookingAttemptViewingInteraction = (phone: string | string[], roomId: string | string[]) => {
  return client.post(ADD_ROOM_INTERACTION_PATH, {
    phone,
    roomId,
    interactionType: 'BookingAttempt'
  }).catch(logError);
}

const apiConfirmBooking = (viewingId: string | string[], phoneNumber: string | string[]) => {
  return client.post(CONFIRM_VIEWING_PATH, {
    viewSlotId: viewingId, phone: phoneNumber
  }).catch(logError);
}

const postQuestion = (id: string, event: QuestionAnswer) => {
  return client.post(`${SCREENINGS_PATH}/${id}/question-answer`, event).catch(logError);
}

const getScreening = (id: string) => {
  return client.get(`${SCREENINGS_PATH}/${id}`).then((res) => res.data).catch(logError);
}

const getViewingSlots = (id: string) => {
  return client.get(`${SCREENINGS_PATH}/${id}/viewing-slots`).then((res) => res.data).catch(logError);
};

const validateQuestions = (id: string, event: ValidateSession) => {
  return client.post(`${SCREENINGS_PATH}/${id}/validate`, event).then((res) => res.data).catch(logError);
};

const bookViewingSlot = (id: string, event: BookViewingSlot) => {
  return client.post(`${SCREENINGS_PATH}/${id}/book`, event).then((res) => res.data).catch(logError);
};

const rescheduleViewingSlot = (id: string, event: BookViewingSlot) => {
  return client.post(`${SCREENINGS_PATH}/${id}/reschedule`, event).then((res) => res.data).catch(logError);
};

const cancelViewingSlot = (id: string) => {
  return client.post(`${SCREENINGS_PATH}/${id}/cancel`, {}).then((res) => res.data).catch(logError);
};

const updateUserDetails = (id: string, event: UserDetails) => {
  return client.post(`${SCREENINGS_PATH}/${id}/update-details`, event).then((res) => res.data).catch(logError);
};


const confirmScreening = (id: string) => {
  return client.post(`${SCREENINGS_PATH}/${id}/confirm`, {}).then((res) => res.data).catch(logError);
};

export {
  getQuestions,
  getViewingSlots,
  bookViewing,
  doRejection,
  addBookingAttemptViewingInteraction,
  apiConfirmBooking,
  postQuestion,
  getScreening,
  validateQuestions,
  bookViewingSlot,
  rescheduleViewingSlot,
  cancelViewingSlot,
  updateUserDetails,
  confirmScreening
}
