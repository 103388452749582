import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import logo from '../images/logo.svg'
import { MainProps } from '../types/MainProps';
import QuestionProgress from './QuestionProgress';

const renderStages = ['success', 'cancelledSession', 'confirmed', 'booked', 'error'];

const QuestionText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const GridColor = styled(Grid)(({ theme }) => ({
    //height: '100%',
    //backgroundColor: theme.palette.primary.main,
}));

const GridPadding = styled(Grid)(({ theme }) => ({
    paddingTop: 30,
    // paddingRight: 40,
    paddingBottom: 20,
    // paddingLeft: 50,
}));

function MobileTopPannel(props: MainProps) {
    const { selectedQuestion, stage } = props;
    const { question } = selectedQuestion;
    return (
        <>
            <GridColor
                item
                xs={4}
                sx={{ width: '100%', px: 2 }}
            >
                <GridPadding
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={6} style={{
                        height: 100,
                        paddingBottom: 20,
                    }}>
                        <img src={logo} alt="test" style={{
                            height: 100
                        }} />
                    </Grid>
                    {
                        stage === 'questions' && <Grid item sx={{ width: '100%' }} xs={1}>
                            <QuestionProgress {...props} />
                        </Grid>
                    }
                    {
                        !renderStages.includes(stage) && <Grid item xs={6}>
                            <QuestionText variant="h5" gutterBottom >
                                {question}
                            </QuestionText>
                        </Grid>
                    }
                </GridPadding>
            </GridColor>
        </>
    );
}

export default MobileTopPannel;
