import React from 'react';
import Grid from '@mui/material/Grid';
import MobileTopPannel from '../components/MobileTopPannel';
import MobilePannelBottom from '../components/MobilePannelBottom';
import { MainProps } from '../types/MainProps';

function MobileView(props: MainProps) {

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <MobileTopPannel {...props} />
        <MobilePannelBottom {...props} />
      </Grid>
    </>
  );
}

export default MobileView;
