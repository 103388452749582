import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { MainProps } from '../types/MainProps';
import { startTemplate, welcomeTemplate } from '../strings/templates';

const TypographyColor = styled(Typography)(({ theme }) => ({
    color: 'white',
    margingLeft: 10,
    paddingRigth: 10,
}));

function WelcomeResponse(props: MainProps) {
    const { setStage, questions = [], setSelectedQuestion } = props;

    return (
        <>
            <Grid
                container
                direction="column"
                // justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={2}>
                    <TypographyColor sx={{ p: 3 }} variant="h6" dangerouslySetInnerHTML={{ __html: welcomeTemplate()}} gutterBottom align="center" />
                </Grid>
                <Grid item xs={0}>
                    <Button variant="contained" sx={{ py: 2, px: 10, backgroundColor: "#363feb" }} fullWidth onClick={() => {
                        setStage('questions')
                        setSelectedQuestion(questions[0])
                    }}>{startTemplate()}</Button>
                </Grid>
            </Grid>
        </>
    );
}

export default WelcomeResponse;
